<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Productos
            <v-btn
              class="ma-2 btnSend"
              outlined
              color="primary"
              text
              small
              @click="dialog = !dialog"
            >
              <v-icon left>add</v-icon>Nuevo
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>

          <v-data-table
            :loading="load"
            loading-text="Cargando... por favor espere"
            :headers="headers"
            :search="search"
            :items="products"
            no-data-text="No hay datos disponible."
            :items-per-page="5"
            class="elevation-1"
          >
            <template v-slot:item.img="{ item }">
              <div class="p-2">
                <v-img
                  :src="item.img"
                  :alt="item.name"
                  width="50px"
                  height="50px"
                ></v-img>
              </div>
            </template>
            <template v-slot:item.id="{ item }">
              <v-icon small @click="editProduct(item)" class="mr-3"
                >edit</v-icon
              >
              <v-icon color="red" small @click="deleteProduct(item)"
                >delete</v-icon
              >
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" persistent max-width="800">
      <v-card>
        <v-card-title class="headline text-center mb-5 justify-center">
          {{ !isEdit ? "Editar Producto" : "Crear Producto" }}
        </v-card-title>
        <v-card-text>
          <v-form v-model="valid">
            <v-row>
              <v-col cols="12" md="8" sm="8" lg="8">
                <v-text-field
                  v-model="product.name"
                  label="Nombre del Articulo"
                  outlined
                  dense
                  :rules="[rules.required]"
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" sm="4" lg="4">
                <v-select
                  :items="menus"
                  v-model="selectMenu"
                  dense
                  name="category"
                  :rules="[rules.required]"
                  item-text="title"
                  label="Seleccione"
                  return-object
                  solo
                ></v-select>
              </v-col>
              <v-col cols="12" md="4" sm="4" lg="4">
                <v-select
                  :items="getSelectMenu"
                  v-model="selectCategori"
                  name="category"
                  dense
                  :rules="[rules.required]"
                  item-text="title"
                  label="Categoria"
                  return-object
                  solo
                ></v-select>
              </v-col>
              <v-col cols="12" md="4" sm="4" lg="4">
                <v-select
                  :items="getSubCategory"
                  v-model="product.itemSubCategory"
                  dense
                  label="SubCategoria"
                  solo
                ></v-select>
              </v-col>
              <v-col cols="12" md="4" sm="4" lg="4">
                <v-text-field
                  v-model="product.price"
                  :rules="[rules.required]"
                  label="Precio"
                  dense
                  prefix="RD$"
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  outlined
                  :rules="[rules.required]"
                  name="input-7-4"
                  dense
                  label="Descripcion"
                  v-model="product.description"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="6" sm="6" lg="6">
                <v-select
                  :rules="[rules.required]"
                  :items="items"
                  dense
                  multiple
                  item-text="value"
                  return-object
                  v-model="value"
                  label="Tamaño"
                  solo
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" sm="6" lg="6">
                <v-select
                  :rules="[rules.required]"
                  dense
                  v-model="valueColors"
                  multiple
                  return-object
                  item-text="color"
                  :items="colors"
                  label="Color"
                  solo
                ></v-select>
              </v-col>
              <v-col
                v-for="(image, index) in images"
                :key="index"
                cols="12"
                md="3"
                lg="3"
                sm="3"
              >
                <v-card color="#fcfcfc" flat>
                  <v-img
                    :src="
                      image.url === undefined
                        ? image.localData || ''
                        : image.url
                    "
                    height="150"
                  ></v-img>
                  <v-card-actions>
                    <v-spacer />
                    <input
                      type="file"
                      style="display: none"
                      :ref="index + '-image'"
                      accept="image/*"
                      @change="onFilePicked"
                    />
                    <v-btn
                      @click="pickFile(index)"
                      :color="
                        image.url
                          ? 'red'
                          : image.file === undefined
                          ? 'blue'
                          : 'red'
                      "
                      text
                    >
                      |
                      {{
                        image.url
                          ? "Cambiar foto"
                          : image.file === undefined
                          ? "Cargar foto"
                          : "Cambiar foto"
                      }}
                      <v-icon right>photo_camera</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="closeDialog()">
            Cancelar
          </v-btn>
          <v-btn :loading="loading3" color="green" text @click="submit()">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { firestore, firebase, storage } from "@/firebase";
const productCollection = firestore.collection("products");
export default {
  data() {
    return {
      search: "",
      load: true,
      isEdit: true,
      selectMenu: {},
      selectCategori: {},
      selectSubCategori: {},
      selectColor: null,
      sizes: [],
      loading3: false,
      dialog: false,
      colors: [
        {
          color: "AMARILLO",
          code: "#ffff00",
          selected: false,
          visible: true,
        },
        {
          color: "AZUL",
          code: "#0000ff",
          selected: false,
          visible: true,
        },
        {
          color: "BEIGE",
          code: "#f5f5dc",
          selected: false,
          visible: true,
        },
        {
          color: "BLANCO",
          code: "#FFFFFF",
          selected: false,
          visible: true,
        },
        {
          color: "GRIS",
          code: "#9b9b9b",
          selected: false,
          visible: true,
        },
        {
          color: "CAQUI",
          code: "#e0d8b0",
          selected: false,
          visible: true,
        },
        {
          color: "MARRÓN",
          code: "#804000",
          selected: false,
          visible: true,
        },
        {
          color: "METALIZADO",
          code: "#e3e4e5",
          selected: false,
          visible: true,
        },
        {
          color: "MORADO",
          code: "#572364",
          selected: false,
          visible: true,
        },
        {
          color: "NARANJA",
          code: "#ff8000",
          selected: false,
          visible: true,
        },
        {
          color: "NEGRO",
          code: "#000000",
          selected: false,
          visible: true,
        },
        {
          color: "ROJO",
          code: "#FF0000",
          selected: false,
          visible: true,
        },
        {
          color: "ROSA",
          code: "#ff0080",
          selected: false,
          visible: true,
        },
        {
          color: "VERDE",
          code: "#008f39",
          selected: false,
          visible: true,
        },
        // {
        //   color: "CRUDOS",
        //   code: null,
        //   selected: false,
        //   visible: true,
        // },
        // {
        //   color: "ESTAMPADO",
        //   code: null,
        //   selected: false,
        //   visible: true,
        // },
        // {
        //   color: "OTROS",
        //   code: null,
        //   selected: false,
        //   visible: true,
        // },
      ],

      valueColors: [],
      items: [
        { value: "XS", visible: true, selected: false, avalible: true },
        { value: "S", visible: true, selected: false, avalible: true },
        { value: "M", visible: true, selected: false, avalible: true },
        { value: "L", visible: true, selected: false, avalible: true },
        { value: "XL", visible: true, selected: false, avalible: true },
        { value: "XXL", visible: true, selected: false, avalible: true },
        { value: "XS-S", visible: true, selected: false, avalible: true },
        { value: "S-M", visible: true, selected: false, avalible: true },
        { value: "M-L", visible: true, selected: false, avalible: true },
      ],
      value: [],
      valid: false,
      rules: {
        required: (value) => !!value || "Campo requerido.",
      },
      product: {
        name: "",
        price: 0,
        sizes: null,
        quantity: 0,
        colors: null,
        description: "",
        imgs: null,
        category: null,
        subCategory: null,
        itemSubCategory: null,
      },
      headers: [
        {
          text: "Imagen",
          align: "start",
          sortable: false,
          value: "img",
        },
        { text: "Articulo", value: "name" },
        { text: "Seccion", value: "category" },
        { text: "Categoria", value: "subCategory" },
        { text: "Acciones", value: "id" },
      ],

      imageIndex: -1,
      images: [{}, {}, {}, {}],
    };
  },
  beforeMount() {
    this.loadMenus();
    this.loadProducts();
  },
  computed: {
    ...mapGetters(["menus", "products"]),
    getSelectMenu() {
      return this.selectMenu == null ? "" : this.selectMenu.subCategories;
    },
    getSubCategory() {
      return this.selectCategori == null
        ? ""
        : this.selectCategori.subCategories;
    },
  },
  methods: {
    ...mapActions([
      "getMenus",
      "getProducts",
      "createProduct",
      "deleteProductAction",
    ]),

    async loadProducts() {
      await this.getProducts();
    },
    async submit() {
      if (this.valid) {
        this.loading3 = true;
        this.load = true;
        if (this.value.length > 0) {
          this.product.sizes = this.value;
        }
        if (this.valueColors.length > 0) {
          this.product.colors = this.valueColors;
        }
        if (this.selectMenu.title) {
          this.product.category = this.selectMenu.title;
        }
        if (this.selectCategori.title) {
          this.product.subCategory = this.selectCategori.title;
        }
        if (this.isEdit) {
          if (this.images.length == 0) {
            this.$emit("show-snackbar", "Tiene que seleccionar una imagen");
            return;
          }
        }
        const modifiedImages = this.images.filter((i) => i.file !== undefined);
        let modifiedImagesUrl = [];

        console.log(modifiedImages);
        // if (modifiedImages.length == 0) {
        //   this.loading3 = false;
        //   this.$emit("show-snackbar", "Tiene que seleccionar una img");
        //   return;
        // }

        for (const image of modifiedImages) {
          try {
            const downloadUrl = await this.uploadImageAsPromise(image.file);

            modifiedImagesUrl.push(downloadUrl);
          } catch (e) {
            this.loading3 = false;

            this.$emit("show-snackbar", e.message);
            return;
          }
        }
        if (modifiedImagesUrl.length > 0) {
          this.product.imgs = modifiedImagesUrl;
          this.product.img = modifiedImagesUrl[0];
        }

        if (this.isEdit)
          this.product.created = firebase.firestore.Timestamp.fromDate(
            new Date()
          );

        const promise = this.isEdit
          ? productCollection.doc().set(this.product)
          : productCollection.doc(this.product.id).update(this.product);

        promise
          .then(() => {
            this.getProducts();
            this.closeDialog();
            if (!this.isEdit) {
              this.$emit("show-snackbar", "Se ha actualizado el producto");
              return;
            }
            this.$emit("show-snackbar", "Se ha creado el producto");
          })
          .catch((error) => {
            this.$emit("show-snackbar", error.message);
          })
          .finally(() => (this.load = false));
        this.loading3 = false;
      }
    },
    async loadMenus() {
      await this.getMenus();
      this.load = false;
    },
    closeDialog() {
      this.dialog = false;
      this.images = [{}, {}, {}, {}];
      this.product = {
        name: "",
        price: 0,
        sizes: null,
        quantity: 0,
        color: "",
        description: "",
        imgs: null,
        category: null,
        subCategory: null,
        itemSubCategory: "",
      };
      this.selectMenu = {};
      this.selectCategori = {};
      this.load = false;
      this.value = [];
      this.sizes = [];
      this.valueColors = [];
    },
    editProduct(item) {
      this.isEdit = false;
      (this.dialog = true), (this.product = item);
      // this.selectMenu.title = this.product.category;
      // this.selectCategori.title = this.product.subCategory;

      if (this.product.imgs) {
        for (let i = 0; i < this.product.imgs.length; i++) {
          this.images[i].url = this.product.imgs[i];
        }
      }
    },
    async deleteProduct(item) {
      if (confirm("Estas seguro de eliminar el producto?")) {
        await this.deleteProductAction(item);
        this.$emit("show-snackbar", "Se ha eliminado el producto");
        this.loadProducts();
        console.log("Thing was saved to the database.");
        return;
      } else {
        // Do nothing!
        console.log("Thing was not saved to the database.");
        return;
      }
    },

    pickFile(index) {
      this.imageIndex = index;
      this.$refs[index + "-image"][0].click();
    },
    uploadImageAsPromise(imageFile) {
      return new Promise(function(resolve, reject) {
        var storageRef = storage.ref("products/" + Date.now() + ".jpeg");

        //Upload file
        var task = storageRef.put(imageFile);

        //Update progress bar
        task.on(
          "state_changed",
          function(snapshot) {
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED:
                console.log("Upload is paused");
                break;
              case firebase.storage.TaskState.RUNNING:
                console.log("Upload is running");
                break;
            }
          },
          function error(err) {
            reject(err);
          },
          function complete() {
            task.snapshot.ref.getDownloadURL().then(function(downloadURL) {
              resolve(downloadURL);
            });
          }
        );
      });
    },
    onFilePicked(e) {
      const files = e.target.files;

      if (files[0] !== undefined) {
        const image = {};

        image.file = files[0];
        image.localData = URL.createObjectURL(files[0]);

        this.$set(this.images, this.imageIndex, image);
      }
    },
  },
};
</script>

<style lang="css">
.cardTitle {
  text-align: center;
}
</style>
